/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(function() {
                    // $.scrollify({
                    //     section : ".j-slide",
                    //     interstitialSection:".j-slide--footer"
                    // });
                });
            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                header.on('click', '.o-header--toggle', function () {
                    header.toggleClass('s-menu-open');
                });
                
                var menu = $('#wp-primary-menu');
                menu.find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
                menu.find('.menu-slide').on('click',function(e){
                    $(this).toggleClass('open');
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).siblings('.sub-menu').stop(true, true).slideToggle(200).closest('.menu-item').toggleClass('menu__is-open');
                });

                
                var testimonials = $('.j-testimonials');
                if(testimonials.length > 0 ){
                    testimonials.slick({
                        arrows: true,
                        dots: true,

                    });
                }

                var introduction = $('.j-introduction');
                if(introduction.length > 0 ){
                    introduction.on('click','.j-introduction--show',function(){
                        $(this).parents('.j-introduction').toggleClass("o-page-introduction__show-all");
                    });
                }
                var faqs = $('.j-faqs');
                if(faqs.length > 0 ){
                    faqs.on('click','.j-faqs--toggle',function(){
                        var _this = $(this);
                        var parent =  _this.parents('.j-faqs--faq');
                        // Close other opened faqs
                        parent.siblings().removeClass('o-faqs__is-open').find('.j-faqs--answer').slideUp();
                        // Toggle the current selection
                        parent.toggleClass('o-faqs__is-open').find('.j-faqs--answer').slideToggle();
                    });
                }

                
            }
        },
        // Home page
        'page_template_homepage': {
            init: function () {
                // JavaScript to be fired on the home page

          

            },
            finalize: function () {
              
            }
        },

        // Home page
        'blog': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

